const cartItemStyles = /*css*/ `
  :host {
    --font-color: var(--cart-drawer-font-cl, #000000);
    --font-color-sec: var(--cart-drawer-font-cl-sec, #606060);
    --item-border: var(--cart-drawer-item-bd, #cccccc);
    --item-background: var(--cart-drawer-item-bg, #ffffff);
    --quantity-btn-size: var(--cart-drawer-item-qty-size, 32px);
    --quantity-btn-bg: var(--cart-drawer-item-qty-bg, #ffffff);
    --quantity-btn-bd: var(--cart-drawer-item-qty-bd, #a3a3a3);
    --quantity-btn-cl: var(--cart-drawer-item-qty-cl, #000000);
    --quantity-btn-cl-disabled: var(--cart-drawer-item-qty-cl-disabled, #a3a3a3);
    --quantity-btn-bd-disabled: var(--cart-drawer-item-qty-bd-disabled, #d4d4d4);
    --sample-label-font: var(--cart-drawer-item-sample-font, inherit);
    --item-name-display: var(--cart-drawer-item-name-display, flex);
    --item-name-margin: var(--cart-drawer-item-name-margin, 0 8px 0 0);
    --item-name-font: var(--cart-drawer-item-name-font, inherit);
    --item-name-text-transform: var(--cart-drawer-item-name-text-transform, normal);
    --item-details-color: var(--cart-drawer-item-details-cl, #000000);
    --item-details-text-transform: var(--cart-drawer-item-text-transform, none);
    --item-details-shade-radius: var(--cart-drawer-item-shade-radius, 50%);
    --item-details-shade-border-color: var(--cart-drawer-item-shade-border-color, #737373);
    --item-details-shade-border-padding: var(--cart-drawer-item-shade-border-padding, 4px);
    --item-shade-text-transform: var(--cart-drawer-item-shade-transform, none);
    --item-sub-header-font: var(--cart-drawer-item-sub-header-font, var(--price-font));
    --button-remove-font-size: var(--cart-drawer-remove-button-font-size, 0.875rem);
    --button-remove-font-weight: var(--cart-drawer-remove-button-font-weight, 400);
    --button-remove-line-height: var(--cart-drawer-remove-button-line-height, 1.5);
    --button-remove-letter-spacing: var(--cart-drawer-remove-button-letter-spacing, 0.005em);
    --price-font: var(--cart-drawer-item-price-font, inherit);
    --price-font-size: var(--cart-drawer-item-price-font-size, 0.875rem);
    --price-text-transform: var(--cart-drawer-item-price-text-transform, none);
    --prices-weight: var(--cart-drawer-item-prices-weight, 400);
    --discounted-price-opacity: var(--cart-drawer-item-discounted-op, 1);
    --discounted-price-color: var(--cart-drawer-item-discounted-cl, --font-color);
    --discount-percent-color: var(--cart-drawer-item-discount-percent-cl, --font-color);
    --old-price-color: var(--cart-drawer-item-old-price-cl, --font-color);
    --button-remove-text-transform: var(--cart-drawer-item-rm-text-transform, capitalize);
    --engraving-line-color: var(--cart-drawer-item-engr-line-cl, #EBEAEF);
    color: var(--font-color);
  }
  ::slotted(span:empty) {
    display: none;
  }
  ::slotted(img) {
    width: var(--cart-drawer-item-image-width, 80px);
    max-height: var(--cart-drawer-item-image-height, auto);
    max-width: 100%;
    margin: auto;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  ::slotted(img.loaded) {
    opacity: 1;
  }
  ::slotted(.cart-item__shade-color),
  .cart-item__shade-color {
    width: 16px;
    height: 16px;
    border-radius: var(--item-details-shade-radius);
  }
  ::slotted(.cart-item__shade-color.multi),
  .cart-item__shade-color.multi {
    display: flex;
    flex-wrap: wrap;
  }
  .cart-item__shade-contents {
    display: flex;
    align-items: center;
  }
  p {
    margin: 0;
  }
  .cart-item {
    display: flex;
    padding: 16px;
    align-items: start;
    background-color: var(--item-background);
  }
  .cart-item.modal.bundled {
    padding: 16px 0;
  }
  .cart-item-container {
    position: relative;
  }
  .cart-item-loader {
    position: absolute;
    top: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    transition-property: height;
    opacity: 0.4;
    transition-delay: 2s;
    background-color: #fff;
  }
  .cart-item-container.loading .cart-item-loader {
    height: 100%;
  }
  .cart-item.overlay {
    border-block-start: 1px solid var(--item-border);
  }
  .cart-item__thumbnail {
    flex: 0 0 88px;
    margin-inline-end: 16px;
    max-height: 106px;
    min-height: 80px;
    width: 80px;
    text-align: center;
    background-color: var(--cart-drawer-item-image-bg-color, transparent);
  }
  .cart-item__thumbnail.bundled {
    margin-inline-end: 0;
    max-height: 86px;
    min-height: 3rem;
    width: 3rem;
  }
  .cart-item__thumbnail.bundled.last_bundled {
    filter: brightness(60%);
  }
  ::slotted(.cart-item__bundled-extra) {
    position: absolute;
    inset-block-start: 35%;
    inset-inline-start: 25%;
    color: white;
    font-size: 1.5rem;
  }
  .cart-item__custom-kit {
    display: flex;
    column-gap: 24px;
    row-gap: 8px;
    margin-block-start: 8px;
    flex-direction: var(--cart-drawer-item-kit-shade-direction, column);
  }
  .cart-item__custom-kit .cart-item__quantity-readonly-label {
    margin-block-start: 0;
  }
  .cart-item__custom-kit .cart-item__price {
    flex: 1;
    margin-block-start: 0;
  }
  .cart-item__kit-items {
    display: flex;
    gap: 8px;
    flex-direction: column;
    margin-block: 8px;
  }
  .cart-item__kit--dramming .cart-item__kit-thumbnail,
  .cart-item__kit--dramming .cart-item__name {
    display: none;
  }
  .cart-item__kit--dramming .kit-shades {
    margin-block-start: 0;
  }
  .cart-item__kit {
    display: flex;
    align-items: start;
    background-color: var(--item-background);
  }
  .cart-item__kit-thumbnail {
    flex: 0 0 40px;
    margin-inline-end: 16px;
    height: 40px;
    text-align: center;
    overflow: hidden;
  }
  .cart-item__kit-thumbnail-image {
    max-width: 100%;
    height: 100%;
  }
  .cart-item__description {
    flex: 1;
  }
  .cart-item__description.bundled {
    display: none;
  }
  .cart-item__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .cart-item__full-name {
    text-decoration: none;
    color: inherit;
  }
  .cart-item__name,
  .cart-item__sub-header {
    flex: 0 0 1;
    display: var(--item-name-display);
    font-size: var(--cart-drawer-item-name-font-size, 0.875rem);
    font-weight: var(--cart-drawer-item-name-font-weight, 600);
    line-height: var(--cart-drawer-item-name-line-height, 1.4);
    word-wrap: break-word;
    letter-spacing: var(--cart-drawer-item-name-letter-spacing, 0.005em);
    margin: var(--item-name-margin);
    font-family: var(--item-name-font);
    text-transform: var(--item-name-text-transform);
  }
  .cart-item__sub-header {
    display: var(--cart-drawer-item-sub-name-display, var(--item-name-display));
  }
  .cart-item__kit-sub-header {
    font-size: var(--cart-drawer-item-details-font-size, 0.875rem);
    margin: 0 8px 0 0;
    font-family: var(--item-sub-header-font);
    text-transform: var(--item-name-text-transform);
  }
  .cart-item__details {
    gap: 8px;
    display: flex;
    align-items: start;
    flex-direction: column;
    font-size: var(--cart-drawer-item-details-font-size, 0.875rem);
    font-weight: var(--cart-drawer-item-details-font-weight, 400);
    line-height: var(--cart-drawer-item-details-line-height, 1.5);
    letter-spacing: var(--cart-drawer-item-details-letter-spacing, 0.005em);
    margin-block-start: var(--cart-drawer-item-details-margin-top, 8px);
    color: var(--item-details-color);
    text-transform: var(--item-details-text-transform, none);
  }
  .cart-item__shade {
    display: flex;
    align-items: center;
    color: var(--cart-drawer-item-shade-cl, var(--item-details-color));
    font-weight: var(--cart-drawer-item-shade-font-weight, normal);
    text-transform: var(--item-shade-text-transform);
    line-height: 1.3;
    row-gap: 8px;
  }
  .kit-shades .cart-item__shade,
  .cart-item__kit-details .cart-item__shade {
    flex-direction: var(--cart-drawer-item-kit-shade-direction, column);
    align-items: start;
  }
  .cart-item_shade-wrapper {
    border: 0.5px solid var(--item-details-shade-border-color);
    padding: var(--item-details-shade-border-padding);
    border-radius: var(--item-details-shade-radius);
    display: inline-block;
    margin-inline-end: 5px;
  }
  .kit-shades .cart-item_shade-wrapper {
    display: flex;
  }
  .cart-item__price .cart-item__sku-type {
    color: var(--font-color-sec);
    text-transform: var(--price-text-transform);
    font-family: var(--sample-label-font);
    font-weight: var(--cart-drawer-item-type-font-weight, normal);
  }
  .cart-item__price {
    display: flex;
    font-family: var(--price-font);
    font-size: var(--price-font-size);
    justify-content: space-between;
    margin-block-start: var(--cart-drawer-item-price-margin-top, 14px);
    letter-spacing: var(--cart-drawer-item-price-letter-spacing);
    line-height: var(--cart-drawer-item-price-line-height, 1.5);
  }
  .cart-item__price .cart-item__prices {
    gap: 4px;
    display: flex;
    flex-wrap: wrap;
    color: var(--font-color);
    font-weight: var(--prices-weight);
    line-height: var(--cart-drawer-item-price-line-height, 1.5);
    margin-inline-end: 8px;
  }
  .cart-item__engraving-price .old-price,
  .cart-item__prices ::slotted(.old-price) {
    opacity: var(--discounted-price-opacity);
    text-decoration: line-through;
    color: var(--old-price-color);
  }
  .cart-item__prices ::slotted(.discount-percent) {
    color: var(--discount-percent-color);
  }
  .cart-item__prices ::slotted(.discounted-price) {
    color: var(--discounted-price-color);
    font-weight: var(--cart-drawer-discounted-price-weight, var(--prices-weight));
  }
  .cart-item__total-price {
    font-weight: var(--cart-drawer-item-total-price-weight, var(--prices-weight));
    font-family: var(--price-font);
    line-height: var(--cart-drawer-item-price-line-height, 1.5);
    letter-spacing: var(--cart-drawer-item-price-letter-spacing);
    text-transform: var(--cart-drawer-total-price-text-transform, uppercase);
  }
  .cart-item__quantity-readonly-label {
    margin: 0;
    line-height: 1.5;
    font-size: var(--cart-drawer-item-quantity-font-size, 0.875rem);
    margin-block-start: 8px;
    color: var(--cart-drawer-item-quantity-readonly-cl, inherit);
  }
  .cart-item-quantity-limit-wrapper,
  .cart-item-quantity-oos-wrapper,
  .cart-item-order-qty-limit-wrapper {
    display: flex;
    position: absolute;
    margin-block-start: 8px;
    inset-inline: 1rem;
  }
  .cart-item__replenishment {
    font-size: 0.75rem;
    line-height: 20px;
    letter-spacing: 1.8px;
    margin-block-start: 8px;
    text-transform: uppercase;
    color: var(--font-color);
  }
  .cart-item__quantity_limit,
  .cart-item__quantity_oos,
  .cart-item__order_quantity_limit {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    color: var(--cart-drawer-item-quantity-limit-color, #a80000);
    font-size: var(--cart-drawer-item-quantity-limit-font-size);
    font-weight: var(--cart-drawer-item-quantity-limit-font-weight);
    line-height: var(--cart-drawer-item-quantity-limit-line-height);
    background-color: var(--cart-drawer-item-quantity-limit-bg, #f9eeee);
    border: 1px solid var(--cart-drawer-item-quantity-limit-border-color);
  }
  .
  .cart-item__quantity_limit::before,
  .cart-item__quantity_oos::before,
  .cart-item__order_quantity_limit::before {
    position: absolute;
    content: '';
    inset-block-start: -8px;
    border-style: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent var(--cart-drawer-item-quantity-limit-border-color) transparent;
  }

  .cart-item__quantity_limit::after,
  .cart-item__quantity_oos::after,
  .cart-item__order_quantity_limit::after {
    position: absolute;
    content: '';
    inset-block-start: -7px;
    border-style: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent var(--cart-drawer-item-quantity-limit-bg) transparent;
  }
  .cart-item-quantity-limit-wrapper.hidden,
  .cart-item-quantity-oos-wrapper.hidden,
  .cart-item-order-qty-limit-wrapper.hidden {
    display: none;
  }
  .cart-item__btn-remove {
    border: none;
    cursor: pointer;
    background: none;
    width: fit-content;
    font-family: var(--button-remove-font);
    font-size: var(--button-remove-font-size);
    font-weight: var(--button-remove-font-weight);
    line-height: var(--button-remove-line-height);
    letter-spacing: var(--button-remove-letter-spacing);
    text-decoration: underline;
    text-transform: var(--button-remove-text-transform);
    margin-block-start: 16px;
  }
  .cart-item__quantity {
    display: inline-flex;
    align-items: center;
    margin-block-start: 14px;
    border: var(--cart-drawer-item-quantity-border, none);
  }
  .cart-item__quantity-input {
    margin: 0;
    border: none;
    padding: var(--cart-drawer-item-quantity-input-padding, 0 12px);
    width: fit-content;
    background-color: transparent;
    color: var(--cart-drawer-item-quantity-cl, #000000);
    font-size: var(--cart-drawer-item-quantity-input-font-size, inherit);
  }
  .cart-item__quantity-button {
    --cart-qty-icon-color: var(--quantity-btn-cl);
    margin: 0;
    width: var(--quantity-btn-size);
    height: var(--quantity-btn-size);
    font-weight: bold;
    text-align: center;
    border: 1px solid var(--quantity-btn-bd);
    background-color: var(--quantity-btn-bg);
    color: var(--quantity-btn-cl);
  }
  .cart-item__quantity-button.disabled {
    --cart-qty-icon-color: var(--quantity-btn-cl-disabled);
    color: var(--quantity-btn-cl-disabled);
    border: solid 1px var(--quantity-btn-bd-disabled);
    cursor: not-allowed;
  }
  .cart-item__quantity-button ::slotted(span[slot]) {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .cart-item__quantity-button.disabled ::slotted(span[slot]) {
    cursor: not-allowed;
  }
  .cart-item__engraving {
    display: flex;
    flex-direction: column;
    margin: 4px 16px 16px;
    border-top: 1px solid var(--engraving-line-color);
  }
  .cart-item__engraving-details {
    display: flex;
    padding-top: 16px;
    justify-content: space-between;
  }
  .cart-item__engraving-details p {
    font-weight: bold;
    font-size: 0.75rem;
    text-transform: uppercase;
  }
  .cart-item__engraving-price {
    gap: 12px;
    display: flex;
    font-weight: bold;
    font-size: 0.75rem;
  }
  .cart-item__engraving-label {
    font-size: 0.813rem;
  }
  .kit-shades {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-items: center;
    font-size: var(--cart-drawer-item-details-font-size, 0.875rem);
    color: var(--item-details-color);
    text-transform: var(--item-details-text-transform, none);
    font-weight: var(--cart-drawer-item-details-font-weight, 400);
    line-height: var(--cart-drawer-item-details-line-height, 1.5);
    letter-spacing: var(--cart-drawer-item-details-letter-spacing, 0.005em);
  }
  .kit-shades .cart-item__shade-contents + .cart-item__size,
  .kit-shades .cart-item__shade-name + .cart-item__size {
    border-left: solid var(--cart-drawer-item-size-border-width, 1px) var(--item-border);
    margin-inline-start: var(--cart-drawer-item-size-margin-top, 8px);
    padding-inline-start: var(--cart-drawer-item-size-padding-left, 8px);
  }
  @media (min-width: 768px) {
    .cart-item__thumbnail.bundled {
      min-height: 3.8rem;
      width: 3.8rem;
    }
  }
`;

const cartItemsListStyles = /*css*/ `
  slot[name="cart-items"] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--cart-drawer-item-bg, #ffffff);
    border-block-end: solid 1px var(--cart-drawer-item-bd, transparent);
  }
  slot[name="cart-items"].bundled {
    gap: 10px;
    flex-direction: row;
    justify-content: flex-start;
    padding-inline: 16px;
  }
  slot[name="cart-items"].bundled::slotted(:nth-child(n+7)) {
    display: none;
  }
  ::slotted(.cart-error-container) {
    padding: 16px;
  }
  .bag-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-block-start: 32px;
  }
  .cart-svg {
    width: var(--cart-drawer-bag-icon-size-width, 20px);
    height: var(--cart-drawer-bag-icon-size-height, 20px);
    fill: var(--cart-drawer-bag-icon-color, #000000);
  }
  .empty-bag-message {
    font-family: var(--cart-drawer-empty-message-font-family);
    font-size: var(--cart-drawer-empty-message-font-size, 1.25rem);
    text-transform: var(--cart-drawer-empty-title-transform, none);
    font-weight: var(--cart-drawer-empty-message-font-weight, normal);
    line-height: var(--cart-drawer-empty-title-line-height, 1.136);
    letter-spacing: var(--cart-drawer-empty-letter-spacing, 0);
    text-align: center;
    color: var(--cart-drawer-empty-message-font-color, --font-color);
    margin-inline: 32px;
    margin-block: 16px 32px;
  }
  .sign-in-link {
    font-size: var(--cart-drawer-sign-in-font-size, 0.875rem);
    font-weight: normal;
    line-height: var(--cart-drawer-sign-in-line-height, 1.428);
    letter-spacing: var(--cart-drawer-sign-in-letter-spacing, 0);
    text-align: center;
    color: var(--cart-drawer-sign-in-font-color, --font-color);
    margin-inline: 32px;
    margin-block: -16px 32px;
  }
  .sign-in-link a {
    color: inherit;
    text-decoration: underline;
  }
  ::slotted(:first-child) {
    --item-border: transparent;
  }
`;

export { cartItemStyles, cartItemsListStyles };
