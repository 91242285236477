const cartHeaderStyles = /*css*/ `
  :host {
    --close-icon-size: var(--cart-drawer-close-icon-size, 24px);
    --close-icon-color: var(--cart-drawer-close-icon-color, #000000);
    --check-icon-size-width: 24px;
    --check-icon-size-height: 24px;
    --error-title-color: var(--cart-drawer-error-title-color, #a80000);
    --error-icon-color: var(--cart-drawer-error-title-color, #a80000);
    --check-icon-color: var(--cart-drawer-title-icon-color, var(--cart-drawer-title-color, #000000));
    --title-bg-color: #ffffff;
    --title-border-color: var(--cart-drawer-title-bd-cl, #d4d4d4);
    --title-font-family: var(--cart-drawer-title-font, inherit);
    --title-font-size: var(--cart-drawer-title-font-size, 1rem);
    --title-text-transform: var(--cart-drawer-title-text-transform, uppercase);
    --title-line-height: var(--cart-drawer-title-line-height, 1.25);
    --title-font-weight: bold;
    --title-letter-spacing: var(--cart-drawer-title-letter-spacing, inherit);
    --title-color: var(--cart-drawer-title-color, #000000);
    --message-fg-color: var(--cart-drawer-font-cl, #000000);
    --message-error-fg-color: var(--cart-drawer-error-font-cl, var(--message-fg-color));
    --message-font-family: inherit;
    --message-font-size: var(--cart-drawer-message-font-size, 0.75rem);
    --message-line-height: 1.25;
    --message-alignment: flex-start;
    --message-bg-color: var(--cart-drawer-message-bg, #fafafa);
    --message-error-bg-color: var(--cart-drawer-error-bg, var(--message-bg-color));
    --modal-title-border-top: var(--cart-modal-title-border-top, none);
    --modal-handle-color: var(--cart-modal-handle-color, #d4d4d4);
  }
  .cart-header {
    display: flex;
    flex-direction: row;
    flex-wrap: var(--cart-drawer-header-flex-wrap, wrap);
    width: calc(100% - 32px);
    align-items: center;
    padding: var(--cart-drawer-header-padding, 12px 16px);
    justify-content: space-between;
    text-transform: var( --title-text-transform);
    background-color: var(--title-bg-color);
    border-bottom: 1px solid var(--title-border-color);
    border-top: var(--cart-drawer-title-border-top, none);
    font-family: var(--title-font-family);
    font-size: var(--title-font-size);
    line-height: var(--title-line-height);
    font-weight: var(--title-font-weight);
    letter-spacing: var(--title-letter-spacing);
    color: var(--title-color);
  }
  .cart-header--modal {
    border-top: var(--modal-title-border-top);
  }
  .cart-header.error {
    color: var(--error-title-color);
    justify-content: var(--cart-drawer-hide-title-justify-content, space-between);
    border-bottom-color: var(--cart-drawer-hide-title-border-color, var(--title-border-color));
  }
  .cart-header-title {
    display: flex;
    gap: 11px;
    align-items: center;
  }
  .cart-header.error .cart-header-title {
    display: var(--cart-drawer-hide-title-display, flex);
  }
  .cart-messages {
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    gap: 8px;
    width: calc(100% - 32px);
    justify-content: center;
    color: var(--message-fg-color);
    background-color: var(--message-bg-color);
    font-family: var(--message-font-family);
    font-size: var(--message-font-size);
    line-height: var(--message-line-height);
    align-items: var(--message-alignment);
    border-bottom: 1px solid var(--cart-drawer-message-border-bottom, transparent);
  }
  .cart-messages.error-messages {
    color: var(--message-error-fg-color);
    background-color: var(--message-error-bg-color);
  }
  .cart-header-title .cart-svg {
    display: block;
  }
  ::slotted(.cart-header__close-button) {
    width: var(--close-icon-size);
    height: var(--close-icon-size);
    fill: var(--close-icon-color);
    cursor: pointer;
  }
  .cart-drawer__handle-wrapper {
    display: var(--modal-handle-display);
    justify-content: center;
    margin-block: -1rem;
    width: 100%;
  }
  ::slotted(.cart-drawer__handle) {
    cursor: grab;
    padding: 1rem;
  }
  ::slotted(.cart-drawer__handle)::before {
    background-color: var(--modal-handle-color);
    border-radius: 2px;
    content: '';
    display: block;
    height: 3px;
    margin-block-end: 10px;
    margin-inline: auto;
    width: 32px;
  }
  @media (min-width: 1024px) {
    :host {
      --title-font-size: var(--cart-drawer-title-font-size, 0.875rem);
      --title-line-height: var(--cart-drawer-title-line-height, 1.43);
    }
  }
`;

const cartMessagesStyles = /*css*/ `
  :host {
    --message-fg-color: var(--cart-drawer-font-cl, #000000);
    --message-font-family: inherit;
    --message-font-size: var(--cart-drawer-message-font-size, 0.75rem);
    --message-line-height: 1.25;
    --message-alignment: flex-start;
    --message-bg-color: var(--cart-drawer-message-bg, #fafafa);
  }
  .cart-messages {
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    gap: 8px;
    width: calc(100% - 32px);
    justify-content: center;
    color: var(--message-fg-color);
    background-color: var(--message-bg-color);
    font-family: var(--message-font-family);
    font-size: var(--message-font-size);
    line-height: var(--message-line-height);
    align-items: var(--message-alignment);
  }
`;

const cartFreeShippingBarStyles = /*css*/ `
  :host {
    --free-shipping-progress-color: var(--cart-drawer-font-cl, #000000);
    --free-shipping-progress-bg-color: var(--cart-drawer-font-cl, #e3e3e3);
    --free-shipping-border-color: var(--cart-drawer-title-bd-cl, #d4d4d4);
  }
  .cart-free-shipping {
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid var(--free-shipping-border-color);
  }
  .cart-free-shipping .cart-free-shipping__container {
    width: 100%;
  }
  .cart-free-shipping .cart-free-shipping__label {
    font-size: 0.813rem;
  }
  .cart-free-shipping .cart-free-shipping__progress-bar {
    width: 100%;
    height: 4px;
    margin-block-start: 10px;
    background-color: var(--free-shipping-progress-bg-color);
  }
  .cart-free-shipping .cart-free-shipping__progress {
    height: 100%;
    background-color: var(--free-shipping-progress-color);
  }
`;

const cartAlertStyles = /*css*/ `
  :host {
    --alert-bg-color: var(--cart-drawer-alert-bg-color, #FEF1F1);
    --alert-font-color: var(--cart-drawer-alert-font-cl, #D0021B);
    --alert-font-transform: var(--cart-drawer-font-cl, #D0021B);
  }
  .cart-alerts ::slotted(.cart-alert__container) {
    display: flex;
    align-items: flex-start;
    font-size: 0.75rem;
    color: var(--alert-font-color);
    margin: 16px;
    text-transform: uppercase;
    font-weight: 450;
    gap: 12px;
    padding: 12px;
    background-color: var(--alert-bg-color);
  }
`;

export default cartAlertStyles + cartHeaderStyles + cartFreeShippingBarStyles;

export { cartAlertStyles, cartFreeShippingBarStyles, cartHeaderStyles, cartMessagesStyles };
