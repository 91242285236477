import { BaseComponent } from '../utils/BaseComponent';
import cartHeaderStyles from './CartHeaderStyles';

export class CartHeader extends BaseComponent {
  constructor() {
    super(cartHeaderStyles);
    this.loadTemplate('cart-header');
  }
}

try {
  if (!customElements.get('cart-header')) {
    customElements.define('cart-header', CartHeader);
  }
} catch (error) {
  // eslint-disable-next-line no-console
  console.info('[Cart 3.0] cart-header definition failed: ', error);
}
