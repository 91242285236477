import { BaseComponent } from '../utils/BaseComponent';

import styles from './CartDrawerStyles';

export class CartDrawer extends BaseComponent {
  constructor() {
    super(styles);
    this.setTemplates();
  }

  private setTemplates() {
    if (this.shadowRoot) {
      const wrapper = document.createElement('div');
      wrapper.classList.add('cart-wrapper');
      wrapper.id = 'cart-wrapper';

      const bgPlaceholder = document.createElement('div');
      bgPlaceholder.classList.add('cart-wrapper__bg-placeholder');
      bgPlaceholder.setAttribute('data-cart-element', 'background');
      bgPlaceholder.classList.add('hidden');

      const background = document.createElement('slot');
      background.classList.add('hidden');
      background.name = 'background';
      background.setAttribute('data-cart-element', 'background');

      const modal = document.createElement('div');
      modal.classList.add('cart-wrapper__modal', 'hidden--onload');
      modal.setAttribute('data-cart-element', 'modal');
      modal.setAttribute('role', 'dialog');
      modal.setAttribute('aria-modal', 'true');

      const modalSlot = document.createElement('slot');
      modalSlot.name = 'modal';
      modal.appendChild(modalSlot);

      const overlay = document.createElement('div');
      overlay.classList.add('cart-wrapper__overlay');
      overlay.classList.add('hidden');
      overlay.setAttribute('data-cart-element', 'overlay');
      overlay.setAttribute('role', 'dialog');
      overlay.setAttribute('aria-modal', 'true');

      const overlaySlot = document.createElement('slot');
      overlaySlot.name = 'overlay';
      overlay.appendChild(overlaySlot);

      wrapper.appendChild(bgPlaceholder);
      wrapper.appendChild(background);
      wrapper.appendChild(modal);
      wrapper.appendChild(overlay);

      this.shadowRoot.appendChild(wrapper);
    }
  }

  get innerElements() {
    return (this.shadowRoot?.getElementById('cart-wrapper')?.children || []) as HTMLElement[];
  }
}

try {
  if (!customElements.get('cart-drawer')) {
    customElements.define('cart-drawer', CartDrawer);
  }
} catch (error) {
  // eslint-disable-next-line no-console
  console.info('[Cart 3.0] cart-drawer definition failed: ', error);
}
