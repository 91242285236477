import { BaseComponent } from '../utils/BaseComponent';
import styles from './CartFooterStyles';

export class CartFooter extends BaseComponent {
  constructor() {
    super(styles);
    this.loadTemplate('cart-footer');
  }
}

try {
  if (!customElements.get('cart-footer')) {
    customElements.define('cart-footer', CartFooter);
  }
} catch (error) {
  // eslint-disable-next-line no-console
  console.info('[Cart 3.0] cart-footer definition failed: ', error);
}
