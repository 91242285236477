const ctaLabels = [1, 2, 3, 4];
const ctaStyles = ctaLabels.reduce((acc, i) => {
  const styles = `
    --button-cta-${i}-bg: var(--cart-drawer-footer-btn-bg-cta-${i}, #ffffff);
    --button-cta-${i}-fg: var(--cart-drawer-footer-btn-cl-cta-${i}, #000000);
    --button-cta-${i}-border: var(--cart-drawer-footer-btn-bd-cta-${i}, #000000);
    --button-cta-${i}-bg-hover: var(--cart-drawer-footer-btn-bg-cta-${i}-hover, #000000);
    --button-cta-${i}-fg-hover: var(--cart-drawer-footer-btn-cl-cta-${i}-hover, #ffffff);
    --button-cta-${i}-border-hover: var(--cart-drawer-footer-btn-bd-cta-${i}-hover, #000000);
    `;

  return `${acc}\n${styles}`;
}, '');

export default /*css*/ `
  :host {
    --footer-bg: #ffffff;
    --footer-top-border-color: var(--cart-drawer-footer-top-border-cl, #f2f2f2);
    --footer-message-font: inherit;
    --footer-message-font-size: var(--cart-drawer-footer-message-font-size, 0.75rem);
    --footer-message-color: var(--cart-drawer-font-cl, #000000);
    --footer-shadow: var(--cart-drawer-footer-shadow, 0px -4px 12px rgba(0, 0, 0, 0.1));
    --footer-shadow-overlay: var(--cart-drawer-overlay-footer-shadow, var(--footer-shadow));
    --total-label-font: inherit;
    --total-label-font-size: var(--cart-drawer-estimated-total-label-font-size, 0.75rem);
    --total-label-color: var(--cart-drawer-font-cl, #000000);
    --total-label-font-weight: normal;
    --total-value-font: inherit;
    --total-value-font-size: var(--cart-drawer-item-price-font-size, 0.75rem);
    --total-value-font-weight: var(--cart-drawer-item-prices-weight, normal);
    --total-value-color: var(--cart-drawer-font-cl, #000000);
    --estimated-total-value-font-weight: var(--cart-drawer-footer-est-total-font-wt, var(--total-value-font-weight));
    --count-font: var(--cart-drawer-footer-count-font, inherit);
    --count-font-size: var(--cart-drawer-item-price-font-size, 0.75rem);
    --count-color: var(--cart-drawer-font-cl, #000000);
    --count-text-transform: var(--cart-drawer-footer-count-text-transform, uppercase);

    ${ctaStyles}
    --button-font-size: var(--cart-drawer-footer-btn-font-size, 0.9375rem);
    --button-font: var(--cart-drawer-footer-btn-font, inherit);
    --button-flex-direction: column;
    --button-letter-spacing: var(--cart-drawer-footer-btn-letter-spacing, 1.8px);
    --button-text-transform: var(--cart-drawer-footer-btn-text-transform, uppercase);
    --button-font-weight: var(--cart-drawer-footer-btn-weight, normal);
    --price-font: var(--cart-drawer-item-price-font, inherit);
    --price-font-size: var(--cart-drawer-item-price-font-size, 0.875rem);
    --button-height: var(--cart-drawer-footer-btn-height, 60px);
    --button-border-radius: var(--cart-drawer-button-border-radius, 0);

    --toast-notification-bg: var(--cart-drawer-toast-container-bg, #404040);
    --toast-notification-color: var(--cart-drawer-toast-font-color, #ffffff);
    --footer-modal-box-shadow: var(--cart-footer-modal-box-shadow, none);
    --footer-modal-border-top: var(--cart-footer-modal-border-top, none);
    --footer-modal-message-display: var(--cart-drawer-footer-modal-message-display, block);
  }
  .cart-footer {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: calc(100% - 32px);
    gap: 8px;
    background-color: var(--footer-bg);
    border-top: solid 1px var(--footer-top-border-color);
    box-shadow: var(--footer-shadow);
  }
  .cart-footer.error {
    --footer-top-border-color: transparent;
  }
  .cart-footer.overlay {
    box-shadow: var(--footer-shadow-overlay, none);
  }
  .cart-footer.modal {
    box-shadow: var(--footer-modal-box-shadow);
    border-block-start: var(--footer-modal-border-top);
  }
  .cart-footer.modal .cart-additional-message {
    display: var(--footer-modal-message-display);
  }
  .cart-details {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-block-end: 8px;
  }
  .cart-buttons {
    display: flex;
    flex-direction: var(--button-flex-direction);
    gap: 12px;
  }
  [class*=cart-button--drawer-cta-],
  .cart-button--close {
    flex: var(--button-flex-props);
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    letter-spacing: var(--button-letter-spacing);
    font-family: var(--button-font);
    font-weight: var(--button-font-weight);
    height: var(--button-height);
    font-size: var(--button-font-size);
    text-transform: var(--button-text-transform);
    border-radius: var(--button-border-radius);
    transition-duration: 0.3s;
  }

  [class*=cart-button--drawer-cta-] ::slotted(.cart-button),
  .cart-button--close ::slotted(.cart-button) {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .cart-button--close,
  .cart-button--drawer-cta-1 {
    border: solid 1px var(--button-cta-1-border);
    background: var(--button-cta-1-bg);
    color: var(--button-cta-1-fg);
  }
  .cart-button--drawer-cta-2 {
    border: solid 1px var(--button-cta-2-border);
    background: var(--button-cta-2-bg);
    color: var(--button-cta-2-fg);
  }
  .cart-button--drawer-cta-3 {
    border: solid 1px var(--button-cta-3-border);
    background: var(--button-cta-3-bg);
    color: var(--button-cta-3-fg);
  }
  .cart-button--drawer-cta-4 {
    border: solid 1px var(--button-cta-4-border);
    background: var(--button-cta-4-bg);
    color: var(--button-cta-4-fg);
  }
  .cart-count {
    align-self: end;
    font-size: var(--count-font-size);
    font-family: var(--count-font);
    font-weight: bold;
    color: var(--count-color);
    text-transform: var(--count-text-transform);
    letter-spacing: var(--cart-drawer-item-price-letter-spacing);
    line-height: var(--cart-drawer-item-price-line-height);
  }
  .cart-estimated-total-label {
    font-size: var(--total-label-font-size);
    font-family: var(--total-label-font);
    color: var(--total-label-color);
    font-weight: var(--total-label-font-weight);
  }
  .cart-estimated-total-value {
    font-size: var(--price-font-size);
    font-family: var(--price-font);
    color: var(--total-value-color);
    font-weight: var(--estimated-total-value-font-weight);
    letter-spacing: var(--cart-drawer-item-price-letter-spacing);
    line-height: var(--cart-drawer-item-price-line-height);
  }
  .cart-additional-message {
    font-size: var(--footer-message-font-size);
    font-family: var(--footer-message-font);
    color: var(--footer-message-color);
  }
  ::slotted(.cart-toast-notification) {
    background-color: var(--toast-notification-bg);
    color: var(--toast-notification-color);
    text-transform: uppercase;
    padding: 0.75rem;
    font-size: 0.75rem;
    position: absolute;
    min-width: 340px;
    max-width: 345px;
    bottom: -100%;
    animation: cartToastSlideIn 0.5s forwards, cartToastSlideOut 0.5s 3s forwards;
  }
  ::slotted(.cart-toast-notification.hidden) {
    display: none;
  }
  @media (min-width: 768px) {
    :host {
      --button-flex-props: 1 1 50%;
      --button-flex-direction: row;
    }
  }
  @media (hover: hover) {
    .cart-button--close:hover,
    .cart-button--drawer-cta-1:hover {
      background: var(--button-cta-1-bg-hover);
      color: var(--button-cta-1-fg-hover);
      border: solid 1px var(--button-cta-1-border-hover);
    }
    .cart-button--drawer-cta-2:hover {
      background: var(--button-cta-2-bg-hover);
      color: var(--button-cta-2-fg-hover);
      border: solid 1px var(--button-cta-2-border-hover);
    }
    .cart-button--drawer-cta-3:hover {
      background: var(--button-cta-3-bg-hover);
      color: var(--button-cta-3-fg-hover);
      border: solid 1px var(--button-cta-3-border-hover);
    }
    .cart-button--drawer-cta-4:hover {
      background: var(--button-cta-4-bg-hover);
      color: var(--button-cta-4-fg-hover);
      border: solid 1px var(--button-cta-4-border-hover);
    }
  }
`;
