const cartEngravingRules = /*css*/ `
  .Optima {
    font-family: Optima;
  }

  .Roman {
    font-family: Nh4Roman;
  }

  .Script {
    font-family: Script;
  }

  .Arial_Uni {
    font-family: ArialOSRegular;
  }

  .Roman_L_Monog_aBc,
  .Roman_L_Monog_abc {
    font-family: Roman_L_Monog_aBc;
  }

  .Interlock_M_Script_aBc,
  .Interlock_M_Script_abc {
    font-family: Interlock-M-Script-abc;
  }
`;

export { cartEngravingRules };
