import {
  CartDrawer,
  CartTimeout,
  FULL_SCREEN_CLASS,
  cartDrawerAnalytics,
  cartDrawerTargets,
  cartDrawerValues,
  fetchCart,
  fetchCartWithATB,
  handlePageScroll,
  IToggleCartModeEvent,
  ICloseEvent,
  setDrawerHeight,
  getDrawerHeight,
  clearDrawerHeight,
  touchPosition,
  setGlobalStyles
} from '../utils/cart';

import { CartDrawer as CartDrawerComponent } from '../webcomponents/CartDrawer/CartDrawer';

export default class extends CartDrawer {
  static targets = cartDrawerTargets;
  static values = cartDrawerValues;

  private readonly CLOSE_THRESHOLD = 25;
  private readonly EXPAND_THRESHOLD = 75;

  private _closingTimeout: CartTimeout;
  private modalContentTarget: HTMLElement;
  private overlayContentTarget: HTMLElement;

  private drawerHeight = 0;
  private dragPosition?: number;
  private fullscreenClass = FULL_SCREEN_CLASS;

  initialize(): void {
    const shadowHost = document.querySelector('[data-controller="cart-drawer"]');
    const shadowRoot = shadowHost?.shadowRoot;
    this.modalContentTarget = shadowRoot?.querySelector('.cart-wrapper__modal') as HTMLElement;
    this.overlayContentTarget = shadowRoot?.querySelector('.cart-wrapper__overlay') as HTMLElement;
  }

  connect(): void {
    this.initialize();
    this.updateContent().then(() => this.toggleComponents());
  }

  stylesTargetConnected() {
    if (this.hasStylesTarget) {
      if (this.stylesLoadedValue) {
        this.stylesTarget.remove();
      } else {
        this.stylesLoadedValue = setGlobalStyles(this.stylesTarget);
      }
    }
  }

  stylesTargetDisconnected() {
    this.stylesLoadedValue = true;
  }

  open(event: IToggleCartModeEvent) {
    this.forceCloseValue = false;
    if (!this.isLoadingValue && !this.blockUpdate) {
      this.blockUpdate = event?.detail?.blockUpdate ?? false;

      this.toggleCartMode(event).then(() => {
        setTimeout(() => {
          if (!this.isDrawerDisabled) {
            handlePageScroll('disable');
            this.displayComponents('enable');
            const initialFocusTarget =
              this.modeValue === 'overlay' ? this.overlayCloseTarget : this.modalCloseTarget;
            initialFocusTarget.focus();
            window.addEventListener('keydown', this.onKeypress);
          }
        }, this.timeOut);
      });

      this.unblockUpdate();
    }
  }

  close(event?: ICloseEvent) {
    event && this.forceClosing(event);
    const delay = event?.detail?.delay ?? this.timeOut;
    const eventType = event && event['type'];

    if (eventType === 'click') {
      cartDrawerAnalytics({
        drawerAction: 'dismiss',
        drawerLabel: 'close_icon',
        mode: this.modeValue
      });
    }

    this._closingTimeout = setTimeout(() => {
      handlePageScroll('enable');
      this.displayComponents('disable');
      window.removeEventListener('keydown', this.onKeypress);
      window.removeEventListener('mousemove', this.onDragMove);
      window.removeEventListener('touchmove', this.onDragMove);
      window.removeEventListener('mouseup', this.onDragEnd);
      window.removeEventListener('touchend', this.onDragEnd);
    }, delay);
  }

  abortClosing() {
    !this.forceCloseValue && clearTimeout(this._closingTimeout);
  }

  forceClosing(event: ICloseEvent) {
    if (!this.forceCloseValue && event?.params && event?.params.forceClose) {
      this.forceCloseValue = true;
    }
  }

  onKeypress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      this.close();
    }
  };

  redirect({ params: { url, analytics } }) {
    cartDrawerAnalytics({ drawerLabel: analytics, drawerAction: 'click', mode: this.modeValue });

    handlePageScroll('enable');
    this.displayComponents('disable');

    if (url) {
      window.location.href = url;
    }
  }

  dismissAlert({ params: { key } }) {
    if (this.hasAlertsTarget) {
      const alertTarget = this.alertsTargets.find((alert) => alert.dataset.key === key);
      if (alertTarget) {
        alertTarget.remove();
      }
    }
  }

  onDragStart = (event: TouchEvent & DragEvent) => {
    const newHeight = getDrawerHeight(this.modalContentTarget);

    setDrawerHeight(this.modalContentTarget, newHeight);
    this.dragPosition = touchPosition(event);

    window.addEventListener('mousemove', this.onDragMove);
    window.addEventListener('touchmove', this.onDragMove, { passive: true });
    window.addEventListener('mouseup', this.onDragEnd);
    window.addEventListener('touchend', this.onDragEnd);
  };

  onDragMove = (event: TouchEvent & DragEvent) => {
    if (this.dragPosition === undefined) {
      return;
    }

    const y = touchPosition(event);
    const deltaY = this.dragPosition - y;
    const deltaHeight = (deltaY / window.innerHeight) * 100;

    const newHeight = this.drawerHeight + deltaHeight;
    setDrawerHeight(this.modalContentTarget, newHeight);

    this.dragPosition = y;
  };

  onDragEnd = () => {
    this.dragPosition = undefined;

    if (this.drawerHeight < this.CLOSE_THRESHOLD) {
      this.close();
      setTimeout(() => {
        clearDrawerHeight(this.modalContentTarget);
      }, 500);
    } else if (this.drawerHeight > this.EXPAND_THRESHOLD) {
      clearDrawerHeight(this.modalContentTarget);
      this.modalContentTarget.classList.add(this.fullscreenClass);
    } else {
      clearDrawerHeight(this.modalContentTarget);
    }
  };

  private unblockUpdate() {
    if (this.blockUpdate) {
      setTimeout(() => {
        this.blockUpdate = false;
      }, 500);
    }
  }

  private displayComponents(action: 'enable' | 'disable') {
    for (const element of (this.element as CartDrawerComponent).innerElements) {
      const elementName = element?.getAttribute('data-cart-element') || '';
      const shouldUpdate = [`${this.modeValue}`, 'background'].includes(elementName);

      const showElement = () => {
        element?.classList.remove('hidden', 'hidden--onload');
      };

      if (shouldUpdate) {
        action === 'enable' ? showElement() : element?.classList.add('hidden');
        if (element.classList.contains('cart-wrapper__bg-placeholder')) {
          this.handleBgPlaceholder(element);
        }
      }
    }
  }

  private handleBgPlaceholder(element: Element) {
    if (element.classList.contains('modal') && this.modeValue !== 'modal') {
      element.classList.remove('modal');
    } else {
      element.classList.remove('overlay');
    }
    element.classList.add(this.modeValue);
  }

  private updateDialogLabel() {
    const label = document.querySelector(`#cart-drawer-header-${this.modeValue}`) as HTMLElement;
    const content =
      this.modeValue === 'overlay' ? this.overlayContentTarget : this.modalContentTarget;
    content.setAttribute('aria-label', label?.textContent || '');
  }

  private async updateContent(params?: IToggleCartModeEvent['detail']) {
    const newContent = await this.getHTMLContent(params);
    if (newContent) {
      this.repaint(newContent);
      if (params?.detail) {
        this.triggerToastNotifications(params.detail);
      }
      this.updateCartIconCount();
      this.updateDialogLabel();
    }
    this.toggleComponents();
  }

  private async getHTMLContent(params?: IToggleCartModeEvent['detail']) {
    const fetchParams = {
      params,
      mode: this.modeValue,
      csrfToken: this.csrfToken,
      endpoint: this.dataEndpointValue,
      errorEndpoint: this.errorEndpointValue
    };

    if (params?.atbResponseData) {
      this.missingDataValue = true;

      return await fetchCartWithATB(fetchParams);
    }

    if (this.missingDataValue) {
      return await fetchCart(fetchParams).finally(() => {
        this.isLoadingValue = false;
        this.missingDataValue = false;
      });
    }

    const htmlResponse = params?.html || params?.detail?.html;
    if (htmlResponse) {
      return htmlResponse;
    }

    this.isLoadingValue = true;

    return await fetchCart(fetchParams).finally(() => {
      this.isLoadingValue = false;
      this.missingDataValue = false;
    });
  }

  private repaint(html: string) {
    const prevScrollPos = this.hasCartItemsListTarget ? this.cartItemsListTarget.scrollTop : 0;
    this.element.innerHTML = html;

    requestAnimationFrame(() => {
      if (this.hasCartItemsListTarget) {
        this.cartItemsListTarget.scrollTop = prevScrollPos;
      }
    });
  }

  private updateCartIconCount() {
    if (this.hasQuantityTarget) {
      const itemsCount = this.quantityTarget.getAttribute('data-value');
      try {
        if (itemsCount) {
          const event = new CustomEvent('setCartIconCount', { detail: { itemsCount } });
          window.dispatchEvent(event);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Could not dispatch event', error);
      }
    }
  }

  private async toggleCartMode(event: IToggleCartModeEvent) {
    const newMode = event?.detail?.cartMode || 'overlay';
    const hasToggled = this.modeValue !== newMode || newMode === 'modal';

    if (hasToggled) {
      this.modeValue = newMode;
      if (newMode === 'modal' || this.hasErrorModalTarget) {
        await this.updateContent(event?.detail);
      }
      this.toggleComponents();
      if (this.missingDataValue && newMode === 'overlay') {
        setTimeout(async () => {
          await this.updateContent();
        }, 1);
      }
    }
  }

  private toggleComponents() {
    if (this.hasDrawerComponentTarget) {
      for (const component of this.drawerComponentTargets) {
        const elementMode =
          component?.getAttribute('data-cart-drawer-mode') || component?.getAttribute('slot') || '';

        if (elementMode !== this.modeValue) {
          component.classList.add('hidden');
        } else {
          component.classList.remove('hidden');
        }
      }
    }
  }

  private triggerToastNotifications(event?: IToggleCartModeEvent['detail']) {
    const { action = '', updatedSkuName = '' } = event || {};

    if (action === 'remove' && this.hasToastNotificationsTarget) {
      const message = this.toastNotificationsTarget.textContent || '';
      this.toastNotificationsTarget.textContent = message.replace(/::[^:]+::/g, updatedSkuName);
      this.toastNotificationsTarget.classList.remove('hidden');

      setTimeout(() => {
        this.toastNotificationsTarget.classList.add('hidden');
      }, 4000);
    }
  }

  private get timeOut() {
    return this.modeValue === 'overlay' ? 500 : 0;
  }

  private get isDrawerDisabled() {
    const cartIcon = document.querySelector('[id="cart-icon"][data-controller~="cart-icon"]');
    if (cartIcon) {
      const isDrawerDisabled = cartIcon.getAttribute('data-cart-icon-is-drawer-disabled-value');

      return isDrawerDisabled === 'true';
    }
  }
}
