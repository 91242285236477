import * as webcomponents from '../webcomponents';
import { isDrp, mountOnVulcanATB } from './utils/drupal';

import {
  isGem,
  mountIconOnGemini,
  mountOnGeminiATB,
  reMountOnCartIconUpdate
} from './utils/gemini';

import {
  debugConsole,
  forceMount,
  isVulcan,
  skipCartDrawer,
  ICartDrawerInjection,
  IComponentOverrides,
  IMountCartDrawer,
  controllerEvents
} from './utils/helpers';

declare global {
  interface Window {
    _mountCartDrawer: (args?: IMountCartDrawer) => Promise<HTMLElement | undefined>;
    _removeLegacyCartDrawer: () => void;
    _overrideCartDrawerComponents: (a: IComponentOverrides[], b?: Element) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    generic: any;
  }
}

const removeCartDrawer = () => {
  const legacyCartDrawerSelectors = ['[data-behavior-cart="modal"]', '.header-gnav-cart__overlay'];

  const query = isVulcan() ? '[data-behaviour="cart-drawer"]' : legacyCartDrawerSelectors.join(',');
  const legacyCartDrawer = document.querySelectorAll(query);
  legacyCartDrawer.forEach((cartDrawer: HTMLElement) => {
    if (isGem()) {
      cartDrawer.style.display = 'none';
    } else {
      cartDrawer.remove();
    }
  });
};

const publishRemoveLegacyCart = () => {
  const newCartEvent = new CustomEvent('cart.removeLegacyCart', {
    detail: {
      removeLegacyCart: true
    }
  });
  window.dispatchEvent(newCartEvent);
};

const removeLegacyCartDrawer = () => {
  if (skipCartDrawer()) {
    return;
  }

  if (isGem()) {
    reMountOnCartIconUpdate(mountOnIcon);
  }
  publishRemoveLegacyCart();

  const observer = new MutationObserver(() => {
    removeCartDrawer();
  });

  if (document?.body) {
    observer.observe(document.body, { childList: true, subtree: true });
  }
};

/**
 * This method overrides the components inside the cart drawer
 * @param cartDrawer - The cart drawer element
 * @param override - The override object - value and at least one optional override are required
 * @param elementName - The name of the component to override - to override an inner element inside
 * a web component (slotted element) use the slot name (specified by CartDrawerSlotName); to override
 * a web component use the web component name (specified by CartDrawerComponentName)
 * @returns true if the component was found and overriden, false otherwise
 * @example overrideCartDrawerComponent(cartDrawer, { override: { value: 'Best sellers', text: true }, elementName: 'button-checkout' })
 * @example overrideCartDrawerComponent(cartDrawer, { override: {
 * styles: `.cart-button--drawer-cta-4 {
 *   border: solid 1px red;
 *   background: green;
 *   color: black; }`
 * },
 * elementName: 'cart-footer' })
 */
const overrideCartDrawerComponent = (
  cartDrawer: Element,
  { override, elementName }: IComponentOverrides
) => {
  const {
    value = '',
    attribute = '',
    text = false,
    className = '',
    styles = '',
    mode = 'overlay'
  } = override;
  const queryMode = `[slot="${mode}"]`;
  const query = styles ? `${elementName}${queryMode}` : `${queryMode} [slot="${elementName}"]`;
  const component = cartDrawer.querySelector(query);

  if (component) {
    if (attribute && component.getAttribute(attribute) !== value) {
      component.setAttribute(attribute, value);
    }
    if (text && component.textContent !== value) {
      component.textContent = value;
    }
    if (className && !component.classList.contains(className)) {
      component.classList.add(className);
    }
    if (styles) {
      overrideCartDrawerComponentStyles(component, styles);
    }

    return true;
  }
  return false;
};

/**
 * This method overrides the styles of a component inside the cart drawer
 * @param component - The component to override - it must be a web component not a slotted element
 * @param styles - The styles to override
 * @example overrideCartDrawerComponentStyles(component, ':root { color: black; }')
 */
const overrideCartDrawerComponentStyles = (component: Element, styles: string) => {
  if (component.shadowRoot) {
    try {
      const previousStyles = component.shadowRoot.adoptedStyleSheets;
      const overridedStyles = new CSSStyleSheet();
      overridedStyles.replaceSync(styles);
      component.shadowRoot.adoptedStyleSheets = [...previousStyles, overridedStyles];
    } catch {
      // support for old browsers
      const previousStyles = component.shadowRoot.querySelector('style');

      if (previousStyles) {
        previousStyles.innerText += styles;
      }
    }
  }
};

/**
 * This method observes the cart drawer and overrides the components
 * once they are rendered. Since the Cart 3.0 drawer is a server side
 * rendered component, we need to override the components everytime the
 * cart drawer is rendered.
 * @param componentOverrides - The overrides array
 * @param element - The cart drawer element
 * @example overrideCartDrawerComponents([
 *    { override: { value: 'Best sellers', text: true }, elementName: 'button-checkout' }
 *    { override: { value: '/products/8431/meilleures-ventes', attribute: 'data-cart-drawer-url-param' }, elementName: 'button-checkout' }
 * ], cart-drawer)
 */
const overrideCartDrawerComponents = (
  componentOverrides: IComponentOverrides[],
  element?: Element
) => {
  if (skipCartDrawer()) {
    return;
  }

  const cartDrawer = element || document.querySelector('cart-drawer');

  const observer = new MutationObserver(() => {
    if (cartDrawer) {
      for (const { override, elementName } of componentOverrides) {
        overrideCartDrawerComponent(cartDrawer, { override, elementName });
      }
    }
  });

  observer.observe(cartDrawer || document.body, { childList: true, subtree: true });
};

const mountOnIcon = () => {
  const query = isVulcan() ? '[data-controller*="cart"]' : '[data-behavior-cart="icon"]';
  const icon = document.querySelector(query);
  if (icon && !icon.hasAttribute('data-mounted')) {
    const controller = icon.getAttribute('data-controller');
    const target = icon.getAttribute('data-controller');
    const actions = icon.getAttribute('data-action');

    icon.setAttribute('data-mounted', 'true');

    icon.setAttribute('data-controller', `${controller ? controller : ''} cart-icon`.trimStart());

    icon.setAttribute('data-cart-target', `${target ? target : ''} cartButton`.trimStart());

    icon.setAttribute(
      'data-action',
      `
        ${actions ? actions : ''}
        click->cart-icon#handleCartIconClick
        mouseenter->cart-icon#handleMouseEnter
        setCartIconCount@window->cart-icon#setItemsCount
      `.trimStart()
    );

    icon.setAttribute('data-cart-icon-cart-icon-url-value', '/checkout/viewcart.tmpl');

    /*
      .site-bag__link-count = MAC US
      .utility-nav__cart-count = EL US - Drupal
      [class*="utility-nav__cart"] = EL US - Gemini
      [data-cart-target="itemsCount"] = Vulcan
    */

    const countIconNodesOrder = [
      '.site-bag__link-count',
      '.utility-nav__cart-count',
      '.gnav-util__icon__cart-count',
      '.header-gnav-cart__count',
      '[class*="utility-nav__cart"]',
      '[data-cart-target="itemsCount"]'
    ];

    const countIconNodes = countIconNodesOrder.reduce((accumulator, selector) => {
      const node = document.querySelector(selector);
      if (node) {
        return [...accumulator, node];
      } else {
        return [...accumulator];
      }
    }, []);

    const iconCount = countIconNodes.length > 0 ? countIconNodes[0] : undefined;

    if (iconCount) {
      iconCount.setAttribute('data-cart-icon-target', 'itemsCount');
    } else if (isGem()) {
      mountIconOnGemini(icon);
    }
  }

  return icon;
};

/**
 * This method requires jQuery since Drupal events are triggered using jQuery
 * so far, this is the only way to listen to the addToCart event we have found
 * but probably there are other events that could trigger the cart drawer
 */
const mountATBListener = () => {
  if (!isVulcan()) {
    const offerElement = document.querySelector('[data-offercode]');

    isDrp() && mountOnVulcanATB(offerElement);

    isGem() && mountOnGeminiATB();
  }
};

const mountCartDrawer = async (params?: IMountCartDrawer) => {
  if (skipCartDrawer()) {
    return;
  }

  try {
    const { cartDrawerEndpoint } = params || {};

    return webcomponents.registerController(['cart-icon', 'cart-drawer', 'cart-item']).then(() => {
      if (!mountOnIcon()) {
        debugConsole('[Cart 3.0] Cart icon not found');

        return;
      }

      const cartDrawer = document.createElement('cart-drawer');
      cartDrawer.setAttribute('data-controller', 'cart-drawer');
      cartDrawer.setAttribute('data-cart-drawer-mode-value', 'overlay');
      cartDrawer.setAttribute('data-action', controllerEvents.join(' '));
      if (cartDrawerEndpoint) {
        cartDrawer.setAttribute('data-cart-drawer-data-endpoint-value', cartDrawerEndpoint);
      }

      document.body.appendChild(cartDrawer);

      mountATBListener();
      debugConsole('[Cart 3.0] Cart drawer mounted');

      return cartDrawer;
    });
  } catch (error) {
    debugConsole(`[Cart 3.0] Unable to mount cart drawer - ${error}`);
  }
};

const fetchCartConfigs = async () => {
  if (skipCartDrawer()) {
    return {};
  }

  const countryAndLanguage = /^\/([a-z]{2})\/([a-z]{2})(\/|$)/i;
  const languageOnly = /^\/([a-z]{2})(\/|$)/i;

  const pathname = window.location.pathname;
  const countryLangPair = countryAndLanguage.exec(pathname);

  const lang = countryLangPair ? countryLangPair[2] : languageOnly.exec(pathname)?.[1];
  const country = countryLangPair ? countryLangPair[1] : null;

  const url =
    '/cart/v3_0/config' +
    (lang ? '?language=' + lang : '') +
    (country ? '&country=' + country : '');

  return fetch(url)
    .then((response) => response.json())
    .catch((error) => {
      debugConsole(`Could not fetch cart configs from ${url} - ${error}`);
      return {
        aud_seg_allow: false,
        cart_drawer: false,
        cart_drawer_automount: false,
        has_audseg: false,
        drawer_route: '/cart/v3_0/drawer'
      };
    });
};

const cartDrawerInjection = async () => {
  if (skipCartDrawer()) {
    return;
  }

  const {
    aud_seg_allow = false,
    cart_drawer = false,
    cart_drawer_automount = false,
    has_audseg = false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    drawer_route = '/cart/v3_0/drawer'
  }: ICartDrawerInjection = await fetchCartConfigs();
  const isValidSeg = has_audseg && aud_seg_allow && (cart_drawer_automount || forceMount());

  if (!has_audseg || isValidSeg) {
    if (cart_drawer || forceMount()) {
      if (cart_drawer_automount || forceMount()) {
        removeLegacyCartDrawer();
        await mountCartDrawer({ cartDrawerEndpoint: drawer_route });
      } else {
        window._mountCartDrawer = mountCartDrawer;
        window._overrideCartDrawerComponents = overrideCartDrawerComponents;
        window._removeLegacyCartDrawer = removeLegacyCartDrawer;
      }
    } else {
      // eslint-disable-next-line no-console
      debugConsole('[Cart 3.0] Cart Drawer is not enabled');
    }
  }
};

if (!document.querySelector('cart-drawer')) {
  cartDrawerInjection();
}
