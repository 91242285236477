import { Context, Controller as StimulusController } from '@hotwired/stimulus';

declare type Constructor<T> = new (...args: unknown[]) => T;
declare function OutletPropertiesBlessing<T>(constructor: Constructor<T>): unknown;

interface IElementCheck {
  key: string;
  requiredName: string;
  sourceName: string;
}

const ELEMENT_CHECKS: IElementCheck[] = [
  {
    key: 'Target',
    requiredName: 'requiredTargets',
    sourceName: 'targets'
  },
  {
    key: 'Class',
    requiredName: 'requiredClasses',
    sourceName: 'classes'
  },
  {
    key: 'Outlet',
    requiredName: 'requiredOutlets',
    sourceName: 'outlets'
  }
];

export class Controller extends StimulusController {
  static blessings: (typeof OutletPropertiesBlessing)[] = [
    ...StimulusController.blessings.filter((bless) => bless.name == 'TargetPropertiesBlessing'),
    CustomTargetPropertiesBlessing
  ];

  constructor(context: Context) {
    super(context);
    if (window.StimulusConfig?.checks_enabled) {
      this.healthCheck();
    }
  }

  static requiredTargets: string[];

  healthCheck() {
    ELEMENT_CHECKS.forEach((check) => {
      this.checkElements(check);
    });
  }

  private checkElements(check: IElementCheck) {
    const elementType = check.key;
    const required = this.constructor[check.requiredName];
    const baseElements = this.constructor[check.sourceName];
    if (required && required.length > 0) {
      const errors: string[] = [];
      const warnings: string[] = [];
      required.forEach((requiredKey) => {
        if (!this[`has${capitalize(requiredKey)}${elementType}`]) {
          errors.push(requiredKey);
        }
        if (!baseElements.includes(requiredKey)) {
          warnings.push(requiredKey);
        }
      });

      if (errors.length > 0) {
        // eslint-disable-next-line no-console
        console.warn(
          `Missing ${elementType} elements for "${this.identifier}" controller: [${errors.join(
            ', '
          )}] (Not found in DOM)`
        );
      }
      if (warnings.length > 0) {
        // eslint-disable-next-line no-console
        console.warn(
          `Some required ${check.sourceName} for "${
            this.identifier
          }" controller are missing: [${warnings.join(' ')}]`
        );
      }
    }
  }

  get csrfToken() {
    return document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '';
  }
}

function readInheritableStaticArrayValues<T, U = string>(
  constructor: Constructor<T>,
  propertyName: string
) {
  const ancestors = getAncestorsForConstructor(constructor);
  return Array.from(
    ancestors.reduce((values, constructor) => {
      getOwnStaticArrayValues(constructor, propertyName).forEach((name) => values.add(name));
      return values;
    }, new Set() as Set<U>)
  );
}

function getAncestorsForConstructor<T>(constructor: Constructor<T>) {
  const ancestors: Constructor<unknown>[] = [];
  while (constructor) {
    ancestors.push(constructor);
    constructor = Object.getPrototypeOf(constructor);
  }
  return ancestors.reverse();
}

function getOwnStaticArrayValues<T>(constructor: Constructor<T>, propertyName: string) {
  const definition = constructor[propertyName];
  return Array.isArray(definition) ? definition : [];
}

function CustomTargetPropertiesBlessing<T>(constructor: Constructor<T>) {
  const targets = readInheritableStaticArrayValues(constructor, 'targets');
  return targets.reduce((properties, targetDefinition) => {
    return Object.assign(properties, propertiesForTargetDefinition(targetDefinition));
  }, {} as PropertyDescriptorMap);
}

function propertiesForTargetDefinition(name: string) {
  return {
    [`${name}Target`]: {
      get(this: StimulusController) {
        const target = this.targets.find(name);
        if (target) {
          return target;
        } else {
          // eslint-disable-next-line no-console
          console.error(`Missing target element "${name}" for "${this.identifier}" controller`);
        }
      }
    },

    [`${name}Targets`]: {
      get(this: StimulusController) {
        return this.targets.findAll(name);
      }
    },

    [`has${capitalize(name)}Target`]: {
      get(this: Controller) {
        return this.targets.has(name);
      }
    }
  };
}

function capitalize(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}
