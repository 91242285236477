import { Controller } from '../utils/controller';

export default class extends Controller {
  static targets = ['itemsCount'];
  static values = {
    isMobile: false,
    cartIconUrl: '/cart',
    isDrawerDisabled: false,
    isRedirecting: false
  };

  declare itemsCountTarget: HTMLElement;
  declare hasItemsCountTarget: boolean;

  declare cartIconUrlValue: string;
  declare isMobileValue: boolean;
  declare isDrawerDisabledValue: boolean;
  declare isRedirectingValue: boolean;

  connect(): void {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile.bind(this));
  }

  checkMobile() {
    this.isMobileValue = window.innerWidth < 1024;
  }

  setItemsCount({ detail: { itemsCount } }) {
    const emptyCartClass = 'cart-empty';
    const classAction = itemsCount === '0' ? 'add' : 'remove';
    const selector = `[data-${this.identifier}-target~="itemsCount"]`;
    const itemsCountTarget = this.hasItemsCountTarget
      ? this.itemsCountTarget
      : this.element?.parentNode?.querySelector(selector);

    if (itemsCountTarget) {
      itemsCountTarget.textContent = itemsCount;
      itemsCountTarget.classList[classAction](emptyCartClass);
    }
  }

  handleMouseEnter() {
    if (!this.isMouseActionDisabled) {
      const event = new CustomEvent('openCart');
      window.dispatchEvent(event);
    }
  }

  handleCartIconClick() {
    this.isRedirectingValue = true;

    if (this.cartIconUrlValue) {
      const event = new CustomEvent('closeCart', { detail: { delay: 0 } });
      window.dispatchEvent(event);
      window.location.href = this.cartIconUrlValue;
    }
    this.isRedirectingValue = false;
  }

  get isMouseActionDisabled() {
    return this.isMobileValue || this.isDrawerDisabledValue || this.isRedirectingValue;
  }
}
