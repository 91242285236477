const externalWebComponents = ['cart-header', 'cart-items-list', 'cart-footer'];

export class BaseComponent extends HTMLElement {
  constructor(styles?: string) {
    super();
    this.attachShadow({ mode: 'open' });
    this.applyStyles(styles);
  }

  public loadTemplate(templateName: string) {
    const template = this.children.namedItem(templateName);
    if (externalWebComponents.includes(templateName)) {
      const existingAttr = this.getAttribute('data-cart-drawer-target');
      const attributes = existingAttr ? `${existingAttr} drawerComponent` : 'drawerComponent';

      this.setAttribute('data-cart-drawer-target', attributes);
    }
    if (template) {
      this.shadowRoot?.appendChild(template);
    }
  }

  private applyStyles(styles?: string) {
    if (this.shadowRoot && styles) {
      try {
        const stylesheet = new CSSStyleSheet();
        stylesheet.replaceSync(styles);
        this.shadowRoot.adoptedStyleSheets = [stylesheet];
      } catch {
        // support for old browsers
        const styleElement = document.createElement('style');
        styleElement.textContent = styles;
        this.shadowRoot.appendChild(styleElement);
      }
    }
  }
}
