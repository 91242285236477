import { BaseComponent } from '../utils/BaseComponent';
import { cartItemsListStyles } from './CartItemStyles';

export class CartItemsList extends BaseComponent {
  constructor() {
    super(cartItemsListStyles);
    this.loadTemplate('cart-items-list');
  }
}

try {
  if (!customElements.get('cart-items-list')) {
    customElements.define('cart-items-list', CartItemsList);
  }
} catch (error) {
  // eslint-disable-next-line no-console
  console.info('[Cart 3.0] cart-items-list definition failed: ', error);
}
