import CartItemController from '../../controllers/cart_item_controller';
import CartIconController from '../../controllers/cart_icon_controller';
import CartDrawerController from '../../controllers/cart_drawer_controller';

export const defaultKeys = {
  cartItem: 'cart-item',
  cartIcon: 'cart-icon',
  cartDrawer: 'cart-drawer'
};

export const defaultIdentifiers = {
  [defaultKeys.cartItem]: CartItemController,
  [defaultKeys.cartIcon]: CartIconController,
  [defaultKeys.cartDrawer]: CartDrawerController
};
