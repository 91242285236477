import { Application } from '@hotwired/stimulus';
import { defaultIdentifiers } from './constants';

declare global {
  interface Window {
    Stimulus: Application;
  }
}

const domReady = new Promise<void>((resolve) => {
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => resolve());
  } else {
    resolve();
  }
});

export const registerController = async (identifiers: string[]) => {
  await domReady;
  const app = window.Stimulus || (window.Stimulus = Application.start());

  identifiers.map((identifier) => {
    if (
      identifier in defaultIdentifiers &&
      !app.controllers.find((controller) => controller.identifier === identifier)
    ) {
      app.register(identifier, defaultIdentifiers[identifier]);
    }
  });
};
