import { IOpenCartEvent } from './helpers';

const openCartEvent = (params: IOpenCartEvent) => {
  const { quantity, collectionId, offerCode, skuId } = params;

  const detail = {
    cartMode: 'modal',
    quantity
  };

  if (skuId) {
    detail['skuId'] = skuId;
  }

  if (offerCode) {
    detail['offerCode'] = offerCode;
  }

  if (collectionId) {
    detail['collectionId'] = collectionId;
  }

  const newCartEvent = new CustomEvent('openCart', { detail });
  window.dispatchEvent(newCartEvent);

  const generic = typeof window.generic === 'object' ? window.generic : {};
  generic?.overlay?.hide();
};

export const isGem = () => '__NEXT_DATA__' in window && typeof window.__NEXT_DATA__ === 'object';

export const reMountOnCartIconUpdate = (mountOnIcon: () => void) => {
  const query = '[data-behavior-cart="icon"]';
  const cartIcon = document.querySelector(query);
  if (cartIcon) {
    const observer = new MutationObserver(() => {
      mountOnIcon();
    });
    observer.observe(cartIcon.parentNode?.parentNode || document.body, { childList: true });
  }
};

export const mountIconOnGemini = (icon: Element) => {
  const iconCountElement = document.createElement('div');
  iconCountElement.setAttribute('data-cart-icon-target', 'itemsCount');
  iconCountElement.setAttribute('data-testid', 'cartOverlayIconCounter');
  iconCountElement.setAttribute('class', 'utility-nav_utility-nav__cart__WxHwB');

  icon.parentNode?.insertBefore(iconCountElement, icon.nextSibling);
};

export const mountOnGeminiATB = () => {
  window.addEventListener('addToCart.success', (event: CustomEvent) => {
    const { cartItems } = event.detail;

    if (cartItems && cartItems.length) {
      const { skuBaseId = '', quantity: itemQuantity = 1 } = cartItems[0];
      const recentAddedItem = document.querySelector(
        `[data-cart-item-sku-id-value="${skuBaseId}"]`
      );
      const recentAddedItemPrevQty =
        recentAddedItem?.getAttribute('data-cart-item-qty-value') || '0';
      const previousItemQuantity = parseInt(recentAddedItemPrevQty, 10);
      const quantity =
        itemQuantity > previousItemQuantity ? itemQuantity - previousItemQuantity : itemQuantity;

      openCartEvent({ skuId: skuBaseId, quantity });
    }
  });
};
