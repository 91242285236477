import { BaseComponent } from '../utils/BaseComponent';

import { cartItemStyles } from './CartItemStyles';
import { cartEngravingRules } from './CartEngravingStyles';

export class CartItem extends BaseComponent {
  constructor() {
    const styles = `${cartItemStyles} ${cartEngravingRules}`;
    super(styles);
    this.loadTemplate(this.templateName);
  }

  get templateName() {
    return this.getAttribute('data-cart-item-type-value') || 'cart_item';
  }
}

try {
  if (!customElements.get('cart-item')) {
    customElements.define('cart-item', CartItem);
  }
} catch (error) {
  // eslint-disable-next-line no-console
  console.info('[Cart 3.0] cart-item definition failed: ', error);
}
